<template>
  <b-container class="bv-example-row">
    <template v-for="({ awardCategory, id, logo, name, company_type, isCandidate, winner_candidate_details }, index) in winners">
     <b-row v-if="!isCandidate"  @click="goToCompanyProfile(id)" class="mt-2":key="index">
      <b-col class="winners-categories-main-cont imgbox" cols="2" >
        <img class="winn-comp-logo" :src="logo" />
      </b-col>
      <b-col class="winners-categories-main-cont info-box ml-1">
        <div class="category-name-box text-center">
          <p class="mb-1 winners-categories-name-text">{{ awardCategory }}</p>
          <b class="font-small">{{ name }}</b>
          <div class="font-small">{{ company_type }}</div>
        </div>
      </b-col>
     </b-row>
     <b-row v-else @click="goToMemberProfile(winner_candidate_details.customer_id)" class="mt-2" :key="index">
      <b-col class="winners-categories-main-cont imgbox" cols="2" >
        <img class="winn-comp-logo" :src="winner_candidate_details.prof_pic" />
      </b-col>
      <b-col class="winners-categories-main-cont info-box ml-1">
        <div class="category-name-box text-center">
          <p class="mb-1 winners-categories-name-text">{{ awardCategory }}</p>
          <b class="font-small">{{ winner_candidate_details.name }}</b>
          <div class="font-small">{{ winner_candidate_details.job_title }}</div>
        </div>
      </b-col>
    </b-row>
  </template>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      winners: [],
      categories: [],
      registrationData: [],
    };
  },

  async mounted() {
    if (this.$route.params.awardId) {
      this.registrationData = await this.fetchRegistrationList(this.$route.params.awardId);
      if (this.registrationData.length && new Date(this.registrationData[0].winners_publication) > Date.now()) {
        this.$router.push({ name: 'AwardInfo', awardId: this.$route.params.awardId, slug: this.$route.params.slug });
      }
      this.categories = await this.fetchCategories(this.$route.params.awardId);
      if (this.categories.length) {
        this.categories.forEach(async (el) => {
          if (el.winner !== null) {
            const { data } = await axios(el.winner);
            this.winners.push({ awardCategory: el.name, ...data, isCandidate: false});
          }

          if (el.winner_candidate !== null) {
            this.winners.push({ awardCategory: el.name, ...el, isCandidate: true});
          }
        });
      }
      const gccWinners = await this.fetchGCCWinners(this.$route.params.awardId);
      gccWinners.forEach(async (el) => {
        const { data } = await axios(el.winner);
        this.winners.push({
          ...data,
          awardCategory: el.name,
        });
      });
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: 'awards/fetchCategories',
      fetchRegistrationList: 'awards/fetchRegistrationList',
      fetchGCCWinners: 'awards/fetchGCCWinners'
    }),
    goToCompanyProfile(id) {
      if (id) {
        const companyId = `${id}`;
        this.$router.push({ name: 'companyProfile', params: { companyId } });
      }
    },
    goToMemberProfile(id) {
      if (id) {
        this.$router.push({ name: 'MemberWall', params: { customerId: id } });
      }
    },
  },
};
</script>

<style scoped>
.winners-categories-name-text {
  color: #bd9d5f;
  text-transform: uppercase;
}
.winners-categories-main-cont {
  background-color: white;
  display: flex;
  padding: 2px;
}
.winn-comp-logo {
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  object-fit: contain;
}
@media (max-width: 768px) {
  .winn-comp-logo {
    width: 100%;
    height: 80px;
    justify-content: space-evenly;
  }

  .imgbox {
    padding: 0 !important;
  }
}
.winn-comp-logo:hover{
  cursor: pointer;
}
.category-name-box{
  margin: auto;
}
.imgbox {
  padding: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 8px;
  cursor: pointer;
}
.info-box {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}
.font-small {
  font-size: 12px;
}
</style>
