var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bv-example-row"},[_vm._l((_vm.winners),function(ref,index){
var awardCategory = ref.awardCategory;
var id = ref.id;
var logo = ref.logo;
var name = ref.name;
var company_type = ref.company_type;
var isCandidate = ref.isCandidate;
var winner_candidate_details = ref.winner_candidate_details;
return [(!isCandidate)?_c('b-row',{key:index,staticClass:"mt-2",on:{"click":function($event){return _vm.goToCompanyProfile(id)}}},[_c('b-col',{staticClass:"winners-categories-main-cont imgbox",attrs:{"cols":"2"}},[_c('img',{staticClass:"winn-comp-logo",attrs:{"src":logo}})]),_c('b-col',{staticClass:"winners-categories-main-cont info-box ml-1"},[_c('div',{staticClass:"category-name-box text-center"},[_c('p',{staticClass:"mb-1 winners-categories-name-text"},[_vm._v(_vm._s(awardCategory))]),_c('b',{staticClass:"font-small"},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"font-small"},[_vm._v(_vm._s(company_type))])])])],1):_c('b-row',{key:index,staticClass:"mt-2",on:{"click":function($event){return _vm.goToMemberProfile(winner_candidate_details.customer_id)}}},[_c('b-col',{staticClass:"winners-categories-main-cont imgbox",attrs:{"cols":"2"}},[_c('img',{staticClass:"winn-comp-logo",attrs:{"src":winner_candidate_details.prof_pic}})]),_c('b-col',{staticClass:"winners-categories-main-cont info-box ml-1"},[_c('div',{staticClass:"category-name-box text-center"},[_c('p',{staticClass:"mb-1 winners-categories-name-text"},[_vm._v(_vm._s(awardCategory))]),_c('b',{staticClass:"font-small"},[_vm._v(_vm._s(winner_candidate_details.name))]),_c('div',{staticClass:"font-small"},[_vm._v(_vm._s(winner_candidate_details.job_title))])])])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }